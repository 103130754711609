import React, { FC, Fragment, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import paths from "../../../ApiServices.json";

import "../../Style/home.css";
import WSHeader from "../WholeSeller/WSHeader";
import {
  formatPoint,
  formatPointMultiplier,
} from "../../PopUpComponents/Popup";
import HeaderSalesman from "../Header/HeaderSalesman";

type SomeComponentProps = RouteComponentProps;
const AreaRetailerNationalLeaderboard: FC<SomeComponentProps> = ({ history }) => {
  const stat: any = history.location.state;
  const [value, setValue] = useState("4");

  // Function untuk menghapus item dari localStorage
  const handleBackClick = () => {
    localStorage.removeItem("topCricketPerformanceVM");
    localStorage.removeItem("topDigitalPerformanceVM");

    // Navigasi kembali ke halaman sebelumnya
    history.push("/areamanager");
  };

  const [groupName, setGroupName] = useState("");
  const [topRetailer, setTopRetailer] = useState<any[]>([]);
  const rowRefs = useRef<(HTMLTableRowElement | null)[]>([]);
  const [roundName, setRoundName] = useState(0);
  const [roundDetail, setRoundDetail] = useState({
    currentRoundId: 0,
    currentRoundName: "",
  });

  const detailRound = paths.employee.getRoundDetails;
  const getRetailer = paths.employee.getRetailer;
  const getTopRetailer = paths.employee.getTopRetailer;
  const retailerCodeHighlight: string | null = localStorage.getItem("wholesellerCode");

  const retailer = {
    wholesellerCode: localStorage.getItem("wholesellerCode"),
  };

  const USER_TOKEN = localStorage.getItem("auth");
  const AuthStr = "Bearer " + USER_TOKEN;

  const round = async () => {
    await axios
      .get(detailRound, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        setRoundDetail(res.data.data);
        localStorage.setItem("roundId", res.data.data.currentRoundId);
        localStorage.setItem("roundName", res.data.data.currentRoundName);
        setRoundDetail({
          currentRoundId: res.data.data.currentRoundId,
          currentRoundName: res.data.data.currentRoundName,
        });
      });
  };

  const getGroup = () => {
    axios
      .get(getRetailer + retailer.wholesellerCode, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        localStorage.setItem(
          "groupName",
          res.data.data.retailerDetail.groupName
        );
        setGroupName(res.data.data.retailerDetail.groupName);
        localStorage.setItem(
          "retailerDetail",
          JSON.stringify(res.data.data.retailerDetail)
        );
        setRoundName(Number(localStorage.getItem("roundId")));
        setRoundDetail({
          currentRoundId: roundName,
          currentRoundName: localStorage.getItem("roundName")?.toString() || "",
        });
        getRetailerStats();
      });
  };

  const getRetailerStats = async () => {
    const dataRetailer = {
      groupName: groupName,
      roundId: roundDetail.currentRoundId,
    };
    await axios
      .post(getTopRetailer, dataRetailer, {
        headers: { Authorization: AuthStr, "Access-Control-Allow-Origin": "*" },
      })
      .then((res) => {
        setTopRetailer(res.data.data);
      })
      .catch((e) => {});
  };

  useEffect(() => {
    if (!localStorage.getItem("roundId")) {
      round();
    } else {
      setRoundDetail({
        currentRoundId: Number(localStorage.getItem("roundId")),
        currentRoundName: localStorage.getItem("roundName")?.toString() || "",
      });
    }
    getGroup();
  }, []);
 
  useEffect(() => {
    if (groupName && roundDetail.currentRoundId) {
      getRetailerStats();
    }
  }, [groupName, roundDetail.currentRoundId]);

  function highlightColor(name: any) {
    if (name === localStorage.getItem("wholesellerCode")) {
      return "#FFE5B4";
    }
  }

  useEffect(() => {
    if (topRetailer.length > 0 && retailerCodeHighlight) {
      const index = topRetailer.findIndex(
        (item) => item.customerCode === retailerCodeHighlight
      );
      if (index !== -1 && rowRefs.current[index]) {
        rowRefs.current[index]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [topRetailer, retailerCodeHighlight]);

  return (
    <Fragment>
      <HeaderSalesman
        groupName={stat.groupName}
        wholesellerName={stat.wholesellerName}
        value={value}
        setValue={setValue}
      />
      <div
        className="col ms-1"
        style={{
          paddingLeft: "0px",
          paddingBottom: "4px",
          fontSize: "larger",
        }}
      >
        <button
          onClick={handleBackClick}
          className="btn btn-link"
          style={{ textDecoration: "none", color: "black" }}
        >
          <i className="fa fa-arrow-left m-3" style={{ color: "black" }} />
        </button>
        <label className="p-3" style={{ marginLeft: "-20px" }}>
          <b>Area Manager</b>
        </label>
      </div>
      <div id="divOne" style={{ maxHeight: "400px", overflowY: "scroll" }}>
        <table id="TblOne" className="table table-bordered table-stripped mb-0">
          <thead>
            <tr style={{ backgroundColor: "#ecbc44" }}>
              <th scope="col" id="WS-Rank">
                Rank
              </th>
              <th scope="col" id="WS-Rank">
                Customer Code
              </th>
              <th scope="col" id="WS-Rank">
                Nama Toko
              </th>
              <th scope="col" id="WS-Rank">
                Area
              </th>
              <th scope="col" id="WS-Rank" style={{ textAlign: "center" }}>
                Multiplier
              </th>
              <th scope="col" id="WS-Rank" style={{ textAlign: "center" }}>
                Base Point
              </th>
              <th scope="col" id="WS-Rank" style={{ textAlign: "center" }}>
                Total Point
              </th>
            </tr>
          </thead>
          <tbody className="table-leaderboard">
            {topRetailer && topRetailer.length > 0 ? (
              topRetailer.map((group, index) => (                
                  <tr
                    key={index}
                    style={{
                      backgroundColor: highlightColor(group.customerCode),
                    }}
                    ref={(el) => (rowRefs.current[index] = el)}
                  >
                    <td>{group.rank}</td>
                    <td>{group.customerCode}</td>
                    <td>{group.retailerName}</td>
                    <td>{group.areaRetailer}</td>
                    <td style={{ textAlign: "right", paddingRight: "100px" }}>
                      {" "}
                      {formatPointMultiplier(group.multiplier, true)}
                    </td>
                    <td style={{ textAlign: "right", paddingRight: "100px" }}>
                      {" "}
                      {formatPoint(group.basePoint)}
                    </td>
                    <td style={{ textAlign: "right", paddingRight: "100px" }}>
                      {formatPoint(group.totalPoint)}
                    </td>
                  </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} style={{ textAlign: "center" }}>
                  Data tidak ditemukan
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default AreaRetailerNationalLeaderboard;
